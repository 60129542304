import {
  LogManager,
  autoinject
} from 'aurelia-framework';
import { Logger } from 'aurelia-logging';
import { AppHttpClient } from "common/services/app-http-client";
import { Question } from 'models/questions/question';
import { UxDatatableParameters, UxDatatableResponse } from "common/resources/elements/ux/datatable/ux-datatable";

@autoinject()
export class QuestionService {
  private logger: Logger;

  constructor(
    public client: AppHttpClient,
    // public model: Question
  ) {

    this.logger = LogManager.getLogger('QuestionsService');
  }

  // setModel(model: Question) {
  //   this.model = model;
  // }

  // getModel(): Question {
  //   return this.model;
  // }

  public async getAll(questionaryId: number): Promise<Question[]> {
    return await this.client.get(`question/questionary/${questionaryId}`)
      .then(response => {
        return <Question[]>response;
      });
  }

  public async list(questionaryId: number, parameters: UxDatatableParameters): Promise<UxDatatableResponse> {
    return await this.client.post(`question/list/${questionaryId}`, parameters)
      .then(response => {
        return <UxDatatableResponse>response;
      });
  }

  public async getById(id: number): Promise<Question> {
    // this.logger.info("id: " +  id); 
    return await this.client.getById("question", id)
      .then(response => {
        return <Question>response;
      });
  }

  public async getNextQuestion(id: number,questionaryId: number,languageId): Promise<Question> {
    return await this.client.get(`question/get-next-question/${id}/${questionaryId}/${languageId}`)
      .then(response => {
        return <Question>response;
      });
  }

  public async create(model: Question): Promise<Question> {
    return await this.client.post('question', model)
      .then(response => {
        return <Question>response;
      });
  }

  public async getAllTranslationsByQuestion(model: Question): Promise<Question[]> {
    return await this.client.post('question/get-all-translations-by-question', model)
      .then(response => {
        return <Question[]>response;
      });
  }

  public async getQuestionOptionByQuestion(model: Question): Promise<Question> {
    return await this.client.post('question/get-question-option-by-question', model)
      .then(response => {
        return <Question>response;
      });
  }

  public async createLocalization(model: Question): Promise<Question> {
    return await this.client.post('question/create-localization', model)
      .then(response => {
        return <Question>response;
      });
  }

  public async update(model: Question): Promise<Question> {
    return await this.client.updateById('question', model.id, null, model)
      .then(response => {
        return <Question>response;
      });
  }

  public async updateLocalization(model: Question): Promise<Question> {
    return await this.client.update(`question/update-localization/${model.id}`, null, model)
      .then(response => {
        return <Question>response;
      });
  }

  async delete(id: number): Promise<void> {
    // this.logger.info("id to delete: " +  id);
    return await this.client.deleteById('question', id)
      .then(() => null);
  }

  async deleteLocalization(id: number, languageId: number): Promise<void> {
    return await this.client.delete(`question/delete-localization/${id}/${languageId}`)
      .then(() => null);
  }

  async uploadImage(image): Promise<any> {
    let formData = new FormData();
    formData.append('file', image);

    return await this.client.post('question/upload', formData)
      .then(response => {
        return <any>response;
      });
  }

}
