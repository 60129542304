import { FileAcceptValidator } from 'common/utils/file-accept-validator';
import { Router } from "aurelia-router";
import { autoinject, LogManager} from "aurelia-framework";
import { Logger } from "aurelia-logging";
import { DialogService } from "aurelia-dialog";
import { ValidationController, ValidationControllerFactory, ValidationRules, ControllerValidateResult } from "aurelia-validation";
import { BootstrapFormRenderer } from "common/services/bootstrap-form-renderer";
import { Notification } from "common/services/notification";
import { QuestionService } from "../../../services/questionaries/question-service";
import { Question } from "models/questions/question";
import { QuestionType } from "models/questions/questionType";
import { QuestionUse } from "models/questions/questionUse";
import { QuestionConfigModal } from "./question-config-modal";
import { ResponseOption } from "models/questions/response-option";
import { CoreLanguageService } from 'main/admin/services/localizations/core-language-service';
import { Language } from 'models/localizations/language';
@autoinject()
export class QuestionEdit {

  private logger: Logger;
  private model: Question = new Question();
  private modelTranslated: Question = new Question();
  //private translatedQuestionnaries: Question[] = [];
  private languages: Language[] = [];
  private isTranslation : boolean = false;
  private questionLanguageId : number = 0;
  private validationController: ValidationController;
  private isAnUpdate: boolean = false;
  private languageId: Language;
  private questionId: number;
  private formulation: string;
  private responseOption: ResponseOption [] = [];
  private responseOptionRules;
  private isTheLastQuestion: boolean = false;
  private languageIdDefault: number = 1;
  constructor(
    private questionService: QuestionService,
    private dialogService: DialogService,
    private notification: Notification,
    public languagesService: CoreLanguageService,
    private validationControllerFactory: ValidationControllerFactory,
    private router: Router) {

    this.logger = LogManager.getLogger("QuestionEdit");

    this.validationController = this.validationControllerFactory.createForCurrentScope();
    this.validationController.addRenderer(new BootstrapFormRenderer());
  }

  public configureValidationRules(): void {
    this.logger.info("configureValidationRules");
    ValidationRules
      .ensure("formulation").displayName("main.admin.pages.questionaries.questions.question").required()
      .ensure("order").displayName("main.admin.pages.questionaries.questions.order").required().matches(/^[0-9]*$/)
      .ensure("questionUse").displayName("main.admin.pages.questionaries.questions.use").required()
      .ensure("code").displayName("main.admin.pages.questionaries.questions.code").required()
      .on(this.model)
  }

  private async activate(params: any): Promise<void> {
    try {
      if (!isNaN(params.questionId)) {
        this.questionId = params.questionId;
        
        /*if (params.isTranslation != undefined && params.languageId != undefined) {
          this.isTranslation = JSON.parse(params.isTranslation);
          this.questionLanguageId = JSON.parse(params.languageId);
        }
        else if (params.languageId != undefined){
          this.questionLanguageId = JSON.parse(params.languageId);
        }*/

        if (params.isTranslation != undefined) {
          this.isTranslation = JSON.parse(params.isTranslation);         
        }
        if (params.languageId != undefined){
          this.questionLanguageId = JSON.parse(params.languageId);
        }
        
        this.languages = await this.languagesService.getAll(); 
       // this.logger.info("Acticated for edit with id", this.questionId);

        this.model = await this.questionService.getById(this.questionId); 
        this.modelTranslated = JSON.parse(JSON.stringify(this.model));
        if(this.isTranslation){
          this.modelTranslated.languageId = this.questionLanguageId;
          this.modelTranslated = await this.questionService.getQuestionOptionByQuestion(this.modelTranslated);
          
        // this.logger.info("que tiene this.modelTranslated", this.modelTranslated);
          if (this.modelTranslated === undefined || this.modelTranslated === null )
          {
            this.modelTranslated = this.model;
            this.formulation = this.model.formulation;
            this.responseOption = JSON.parse(JSON.stringify(this.modelTranslated.responseOptions));         
            this.languages = this.languages.filter( x => x.id != this.questionLanguageId);
          }
          else{            
            this.formulation = this.modelTranslated.formulation;
            this.responseOption = this.modelTranslated.responseOptions;
            this.isAnUpdate = true;
            if (params.languageId != this.languageIdDefault){
              this.languages = this.languages.filter( x => x.id == this.questionLanguageId);
            }else{
              this.languages = this.languages.filter( x => x.id != this.questionLanguageId);
            }
          }
        }

      } else if (!isNaN(params.questionaryId) && !isNaN(params.questionTypeId)) {

        this.logger.debug("params.questionTypeId: ", params.questionTypeId);

        this.model.questionaryId = <number>params.questionaryId;
        this.model.questionType = <QuestionType>params.questionTypeId;

        this.logger.debug("this.model.questionType: ", QuestionType[this.model.questionType]);

        if (this.model.questionType == QuestionType.DescriptiveText || this.model.questionType == QuestionType.OpenAnswerQuestion) {
          this.addResponseOption(true);
        }
      }

      this.configureValidationRules();
    }
    catch (error) {
      this.notification.error(error);
    }
  }

  public async openProperties(): Promise<void> {
    this.logger.debug("openProperties");
    return await this.dialogService.open({
      viewModel: QuestionConfigModal,
      model: this.model
    }).whenClosed(() => null);
  }

  getQuestionTypeValue(): string {
    const questionType = QuestionType[this.model.questionType];
    return questionType;
  }
public async nextQuestion(): Promise<any> {
  let nextQuestion = await this.questionService.getNextQuestion(this.model.id,this.model.questionaryId,this.questionLanguageId);
    if (nextQuestion != null) {      
      await this.router.navigateToRoute("question-child-router", { questionId: nextQuestion.id ,isTranslation: this.isTranslation, languageId: this.questionLanguageId});
      window.location.reload();
    } else{
      this.isTheLastQuestion = true;
      this.notification.info("main.admin.pages.questionaries.questions.messages.no_more_questions");
    }
}

  public async submit(saveAndContinue: boolean): Promise<void> {
    this.logger.debug("submit");
    return this.validationController.validate()
      .then(async (result: ControllerValidateResult) => {
        if (result.valid) {
          if (this.model.responseOptions.length > 0 && this.model.questionType !== QuestionType.DescriptiveText) { //this.questionType.getIdByCode("descriptive.text")
            let validDescriptiveText: boolean = true;
            if (validDescriptiveText) {
              return await this.save(saveAndContinue);
            }
          } else {
            return await this.save(saveAndContinue);
          }
        }
      });
  }

  get canSave() {
    return !this.questionService.client.isRequesting;
  }

  public async save(saveAndContinue: boolean): Promise<any> {
    this.logger.debug("save");
    if (this.modelTranslated.formulation !== undefined || this.model.formulation !== undefined) {
      this.logger.debug("this.model", this.model);
    
      if (this.isTranslation) {
        this.modelTranslated.formulation = this.formulation;
        this.modelTranslated.responseOptions = this.responseOption;
        this.modelTranslated.languageId = this.languageId.id;
        if (this.isAnUpdate) {
          this.modelTranslated.id = this.questionId;
          return this.executeActionWithNotification(
            () => this.questionService.updateLocalization(this.modelTranslated),
            "main.admin.pages.questionaries.questions.messages.question_edited",
            saveAndContinue
          );
        } else {
        return this.executeActionWithNotification(
          () => this.questionService.createLocalization(this.modelTranslated),
          "main.admin.pages.questionaries.questions.messages.question_added",
          saveAndContinue
        );
      } 
      }
      else if (this.model.id !== undefined) {
        return this.executeActionWithNotification(
          () => this.questionService.update(this.model),
          "main.admin.pages.questionaries.questions.messages.question_edited",
          saveAndContinue
        );
      }
      else {
        return this.executeActionWithNotification(
          () => this.questionService.create(this.model),
          "main.admin.pages.questionaries.questions.messages.question_added",
          saveAndContinue
        );
      }
    }
  }
  

  private async executeActionWithNotification(action: () => Promise<Question>, successMessage: string, saveAndContinue: boolean) {
    try {
       
      var questionId;
      var languageId;
      var question =await action()
      if (question)          
          questionId = question.id;         
      else
          questionId = this.model.id;             
      if (this.languageId)
          languageId = this.questionLanguageId;
      else
          languageId = 1 // Spanish default   
      this.notification.success(successMessage);

      if (!saveAndContinue) {
        return this.router.navigateToRoute("questions");
      } else {      
        return this.router.navigateToRoute("question-child-router", { questionId: questionId , isTranslation: this.isTranslation, languageId:languageId});
      }
    } catch (error) {
      this.notification.error(error);
    }
  }

    
  // public cancel(): void {
  //   this.logger.info("cancel", this.router);
  //   this.router.navigate(`questions`);
  // }

  public addResponseOption(isColumn: boolean): void {
    this.logger.info("addReponseOption");
    let responseOption: ResponseOption = new ResponseOption();
    responseOption.id = 0;
    responseOption.order = this.model.responseOptions.length;
    responseOption.isColumn = isColumn;
    responseOption.option = "";
    this.model.responseOptions.push(responseOption);

    //validation
    this.validationController.addObject(responseOption, this.responseOptionRules);
  }

  public deleteResponseOption(index: number) {
    let responseOption = this.model.responseOptions[index];
    //validation
    this.validationController.removeObject(responseOption);
    //delete
    this.model.responseOptions.splice(index, 1);
  }

  public setFile($event: any, item: ResponseOption): void {
    this.logger.debug("setFile");
    let file = <File>$event.target.files[0];
    let fileValidator = FileAcceptValidator.parse("image/*", 1024 * 300);
    if (fileValidator.isValid(file)) {
      let reader: FileReader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let base64data: any = reader.result;
        item.thumbnailImage = base64data;
        item.option = file.name;
        item.thumbnailImageUrl = URL.createObjectURL(file);
      };
    }
    else {
      this.notification.error("main.admin.pages.questionaries.questions.errors.error_bad_image");

    }
  }

  public unSetFile(item: ResponseOption): void {
    item.option = null;
    item.thumbnailImage = null;
    item.thumbnailImageUrl = null;
  }

  public getQuestionUsesByQuestionType(): Array<any> {
    this.logger.debug("getQuestionUsesByQuestionType");
    let result: Array<any> = [];
    this.logger.debug("getQuestionUsesByQuestionType this.model.questionType", this.model.questionType);
    switch (+this.model.questionType) {
      case QuestionType.SingleChoiceList:
      case QuestionType.SingleImageChoiceList:
      case QuestionType.SingleChoiceListWithComments:
      case QuestionType.MultipleChoiceList:
      case QuestionType.MultipleImageChoiceList:
      case QuestionType.Matrix:
        this.logger.debug("QuestionType.All");
        result = Object.keys(QuestionUse)
          .filter(key => QuestionUse[key] === QuestionUse.Evaluation || QuestionUse[key] === QuestionUse.Example)
          .map(key => ({ id: QuestionUse[key], name: key }));
        break;
      case QuestionType.DescriptiveText:
        this.logger.debug("QuestionType.DescriptiveText");
        result = Object.keys(QuestionUse)
          .filter(key => QuestionUse[key] === QuestionUse.Instruction || QuestionUse[key] === QuestionUse.Warning || QuestionUse[key] === QuestionUse.Farewell)
          .map(key => ({ id: QuestionUse[key], name: key }));
        break;
      case QuestionType.OpenAnswerQuestion:
        this.logger.debug("QuestionType.OpenAnswerQuestion");
        result = Object.keys(QuestionUse)
          .filter(key => QuestionUse[key] === QuestionUse.Evaluation || QuestionUse[key] === QuestionUse.Example || QuestionUse[key] === QuestionUse.Warning)
          .map(key => ({ id: QuestionUse[key], name: key }));
        break;
      default:
        break;
    }
    this.logger.debug("getQuestionUsesByQuestionType result:", result);
    return result;
  }

  // public endDragAndDropResponseOption(customEvent: CustomEvent) {
  //   let event = customEvent.detail;

  //   this.logger.debug("endDragAndDropResponseOption event.oldIndex:", event.oldIndex);
  //   this.logger.debug("endDragAndDropResponseOption event.newIndex:", event.newIndex);

  // let movedItem = this.model.responseOptions.find((item, index) => index === event.oldIndex);
  // let remainingItems = this.model.responseOptions.filter((item, index) => index !== event.oldIndex);

  // let j: number = 0;
  // for (let i: number = 0; i < remainingItems.length; i++) {
  //   if (i === event.newIndex) {
  //     movedItem.order = j;
  //     j++;
  //     remainingItems[i].order = j;
  //   } else {
  //     remainingItems[i].order = j;
  //   }
  //   this.logger.debug("endDragAndDropResponseOption i, j:", i, j);
  //   j++;      
  // }

  // if (event.newIndex === remainingItems.length) {
  //   this.logger.debug("endDragAndDropResponseOption last:");
  //   movedItem.order = event.newIndex;
  // }
  // }

}
